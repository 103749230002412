import { Trans } from '@lingui/macro';
import {
  Box,
  Button,
  MenuItem,
  selectClasses,
  Typography,
} from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { MyLink } from 'src/components/core/mine/my-link';
import { MySelect } from 'src/components/core/mine/my-select';
import TokenAmount from 'src/components/core/token-amount';
import { usePlayingCasino } from 'src/hooks/use-playing-casino';
import { stakeCoinAtom, userIdAtom } from 'src/store/auth';
import { openMoneyDropdownAtom } from 'src/store/bet-slip';
import {
  balancesAtom,
  PrettierBalances,
  prettierBalancesAtom,
  pricesAtom,
} from 'src/store/wallet';
import { Paths } from 'src/utils/constants/path';
import { BN } from 'src/utils/helpers/big-number';
import { trackDepositInitiated } from 'src/utils/helpers/rudderstack';
import { makeStyles } from 'tss-react/mui';
import { StakeCoinIcon } from '../stake-coin-icon';

const useStyles = makeStyles()((theme) => {
  return {
    placeholderText: {
      color: `${theme.palette.custom.content[1]} !important`,
    },
    menuList: {
      minWidth: '200px',
    },
  };
});

const MyMoneyDropdown = () => {
  const [open, setOpen] = useAtom(openMoneyDropdownAtom);
  const fetchBalances = useSetAtom(balancesAtom);
  const fetchPrices = useSetAtom(pricesAtom);
  const [stakeCoin, setStakeCoin] = useAtom(stakeCoinAtom);
  const userId = useAtomValue(userIdAtom);
  const isGame = usePlayingCasino();

  const { classes } = useStyles();
  useEffect(() => {
    fetchBalances();
    fetchPrices();
  }, []);

  const prettierBalances = useAtomValue(prettierBalancesAtom);

  const renderValue = (
    balance: PrettierBalances | undefined,
    showUsd = true,
  ) => {
    if (!balance) return null;

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <StakeCoinIcon fiat={false} symbol={balance.symbol} />

        <div>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <Typography fontSize={14}>
              {isGame ? (
                <Trans id="header.casino.played">Inplay</Trans>
              ) : (
                <TokenAmount
                  amount={balance.balance_available}
                  symbol={balance.symbol}
                />
              )}
            </Typography>
            <Typography fontSize={11} sx={{ marginLeft: '4px' }}>
              {balance?.symbol?.toUpperCase()}
            </Typography>
          </Box>
          {showUsd && (
            <Typography
              fontSize={11}
              className="usdbalance"
              color="custom.content.2">
              ${balance.balance_available_in_usd.toString()} USD
            </Typography>
          )}
        </div>
      </Box>
    );
  };

  if (prettierBalances.length === 0) return null;

  return (
    <>
      <MySelect
        MenuProps={{
          classes: {
            list: classes.menuList,
          },
          sx: {
            '.MuiList-root': {
              padding: 0,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          disableScrollLock: true,
        }}
        sx={{
          [`& .${selectClasses.select}`]: {
            py: 0.5,
          },
        }}
        value={stakeCoin}
        displayEmpty
        renderValue={() => {
          // here you can add you logic for your selected value
          const stakeBalance =
            prettierBalances.find((b) => b.symbol === stakeCoin) ||
            ({
              balance_available: new BN(0),
              balance_available_in_usd: new BN(0),
              symbol: stakeCoin,
            } as PrettierBalances);

          return renderValue(stakeBalance, false);
        }}
        onChange={(e) => {
          setStakeCoin(e.target.value as string);
        }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}>
        <MenuItem
          value={undefined}
          disabled
          sx={{
            minHeight: '20px',
            pb: 1.5,
            pt: 1.5,
            opacity: '1 !important',
          }}>
          <Typography
            fontSize={12}
            lineHeight={1}
            className={classes.placeholderText}>
            <Trans id="coindropdown.placeholder">Select a coin to bet</Trans>
          </Typography>
        </MenuItem>
        {prettierBalances.map((balance) => (
          <MenuItem key={balance.symbol} value={balance.symbol}>
            {renderValue(balance)}
          </MenuItem>
        ))}
        <Box
          sx={{
            p: 1,
            opacity: '1 !important',
          }}>
          <Button
            sx={{ width: '100%' }}
            component={MyLink}
            href={Paths.Deposit}
            onClick={() => {
              trackDepositInitiated({
                userId,
                source: 'my-money-dropdown',
              });
            }}>
            <Trans id="deposit">Deposit</Trans>
          </Button>
        </Box>
      </MySelect>
    </>
  );
};

export default MyMoneyDropdown;
