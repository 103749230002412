import { Badge, badgeClasses, Button } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import _ from 'lodash';
import React from 'react';
import { profileAtom } from 'src/store/auth';
import { myAccountAtom } from 'src/store/bet-slip';

interface CurrentUserProps {}

export const CurrentUser: React.FCC<CurrentUserProps> = () => {
  const profile = useAtomValue(profileAtom);
  const [openMyAccount, setOpenMyAccount] = useAtom(myAccountAtom);
  if (!profile) return null;

  return (
    <Badge
      badgeContent={1}
      invisible={profile?.emailVerified}
      color="primary"
      sx={{
        mr: 1,
        [`& .${badgeClasses.badge}`]: {
          minWidth: '16px',
          maxWidth: '16px',
          fontSize: 10,
          lineHeight: 1,
          height: '16px',
          color: 'custom.bg.4',
          top: 2,
          right: 2,
        },
      }}>
      <Button
        onClick={(e) => {
          setOpenMyAccount((prev) => !prev);
        }}
        variant="outlined"
        sx={{
          height: 34,
          width: 42,
          textTransform: 'uppercase',
          ...(!openMyAccount && {
            borderColor: 'custom.content.3',
            bgcolor: 'custom.bg.2',
          }),
        }}>
        {_.first(profile.username)}
      </Button>
    </Badge>
  );
};
