import { Trans } from '@lingui/macro';
import {
  AppBar,
  AppBarProps,
  Box,
  Button,
  Container,
  Tab,
  tabsClasses,
  Toolbar,
  Typography,
} from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { useMemo } from 'react';
import { useActiveBetCounter } from 'src/_pages/home/my-bets/hooks/use-fetch-my-bet';
import TournamentIcon from 'src/assets/icons/casino-config.svg';
import CasinoIcon from 'src/assets/icons/casino.svg';
import EsportIcon from 'src/assets/icons/esports.svg';
import HomeIcon from 'src/assets/icons/home.svg';
import SportIcon from 'src/assets/icons/sports.svg';
import { Logo } from 'src/components/core/logo';
import { MyLink } from 'src/components/core/mine/my-link';
import { QueryMapping, useBreakpoint } from 'src/hooks/use-breakpoint';
import { useMyRouter } from 'src/hooks/use-locale';
import { userIdAtom } from 'src/store/auth';
import { betslipsLengthAtom, openBetSlipAtom } from 'src/store/bet-slip';
import { loginAtom, registerAtom } from 'src/store/layout';
import {
  DESKTOP_NAV_BAR_HEIGHT,
  MOBILE_NAV_BAR_HEIGHT,
} from 'src/utils/constants/keys';
import { Paths } from 'src/utils/constants/path';
import { isDeviceType, MyDevices } from 'src/utils/helpers/etc';
import {
  trackDepositInitiated,
  trackRegisterInit,
} from 'src/utils/helpers/rudderstack';
import { generatePath } from 'src/utils/libs/react-router-dom';
import matchPath from 'src/utils/libs/react-router-dom/matchPath';
import { SportTypeEnum } from 'src/utils/types/sport';
import { ScrollableTabs } from '../scrollable-tabs';
import { CurrentUser } from './current-user';
import { SearchBar } from './search-bar';
import { SeeBalance } from './see-balance';

const getPublicTabs = (isMobile: boolean) => {
  const Home = {
    label: <Trans id="home">Home</Trans>,
    href: Paths.Home,
    path: [Paths.Home],
    icon: HomeIcon,
  };
  const Promotions = {
    label: <Trans id="promotions">Promotions</Trans>,
    href: Paths.Specials,
    path: [Paths.Promotion],
    icon: TournamentIcon,
  };

  const Casino = {
    label: <Trans id="casino">Casino</Trans>,
    href: Paths.LobbyBetUTopPicks,
    path: [generatePath(Paths.MetaCasino)],
    icon: CasinoIcon,
  };
  const Sport = {
    label: <Trans id="sportsbook.sports">Sports</Trans>,
    href: generatePath(Paths.MetaLiveSport, {
      sportType: SportTypeEnum.Sport,
    }),
    path: [
      generatePath(Paths.MetaSport, {
        sportType: SportTypeEnum.Sport,
      }),
    ],
    icon: SportIcon,
  };

  const Esport = {
    label: <Trans id="sportsbook.esports">Esports</Trans>,
    href: generatePath(Paths.MetaLiveSport, {
      sportType: SportTypeEnum.ESport,
    }),
    path: [
      generatePath(Paths.MetaSport, {
        sportType: SportTypeEnum.ESport,
      }),
    ],
    icon: EsportIcon,
  };

  if (isMobile) {
    return [Casino, Sport, Home, Esport, Promotions];
  }
  return [Home, Casino, Sport, Esport, Promotions];
};

export const useNavbarTabs = () => {
  const isMobile = useBreakpoint(MyDevices.MOBILE);
  const tabs = useMemo(() => getPublicTabs(isMobile), [isMobile]);
  const { pathname } = useMyRouter();

  const currentTab = useMemo(() => {
    if (!pathname) return false;

    const homeTab = tabs.find((item) => item.href === Paths.Home);
    const otherTabs = tabs.filter((item) => item.href !== Paths.Home);
    return (
      otherTabs.findLast((x) => {
        const obj = matchPath(pathname, x.path);
        return !!obj;
      })?.href || homeTab?.href
    );
  }, [pathname, tabs]);
  return { tabs, currentTab };
};

export const NavBar: React.FCC<AppBarProps> = (props) => {
  const setLogin = useSetAtom(loginAtom);
  const setRegister = useSetAtom(registerAtom);

  const userId = useAtomValue(userIdAtom);
  const logged = !!userId;

  const activeBetsLength = useActiveBetCounter();

  const betSlipLength = useAtomValue(betslipsLengthAtom);

  const [openBetSlip, setOpenBetSlip] = useAtom(openBetSlipAtom);

  const { tabs, currentTab } = useNavbarTabs();

  const onDeposit = () => {
    trackDepositInitiated({
      userId,
      source: 'header',
    });
  };

  return (
    <AppBar
      position="sticky"
      color="transparent"
      square
      {...props}
      sx={{
        left: '0',
        bgcolor: 'custom.header',
        [QueryMapping.mobile]: {
          bgcolor: 'custom.headerFade',
          backdropFilter: 'blur(20px)',
        },
        transition: (theme) =>
          theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        ...props?.sx,
      }}>
      <Toolbar variant="dense">
        <Container
          sx={{
            display: 'flex',
            overflow: 'auto',
            height: { xs: MOBILE_NAV_BAR_HEIGHT, sm: DESKTOP_NAV_BAR_HEIGHT },
            alignItems: 'center',
            ...(isDeviceType(MyDevices.MOBILE) && { overflowX: 'auto' }),
          }}>
          <Logo href={Paths.Home} sx={{ mr: { xs: 0, sm: 2 } }} />
          {/* @ts-ignore */}
          <ScrollableTabs
            sx={{
              minWidth: 150,
              [QueryMapping.mobile]: {
                display: 'none',
              },
              [`& .${tabsClasses.indicator}`]: {
                '& > span': {
                  bgcolor: 'primary.main',
                },
              },
            }}
            value={currentTab}>
            {tabs.map((item, index) => {
              const { href } = item;

              return (
                <Tab
                  component={MyLink}
                  value={href}
                  noLinkStyle
                  key={index}
                  href={href}
                  label={item.label}
                  sx={{
                    p: 0,
                    flex: '1 0 auto',
                    flexWrap: 'nowrap',
                    minWidth: 'auto !important',
                    '&:not(:last-child)': {
                      mr: 3,
                    },
                  }}
                />
              );
            })}
          </ScrollableTabs>
          <SearchBar sxMobile={{ mx: logged ? 1 : 2 }} />

          {logged && (
            <>
              <Button
                sx={{ mr: 1, [QueryMapping.mobile]: { display: 'none' } }}
                component={MyLink}
                href={Paths.Deposit}
                onClick={onDeposit}>
                <Trans id="deposit">Deposit</Trans>
              </Button>
              <CurrentUser />
              <SeeBalance />
            </>
          )}
          {logged ? (
            <Button
              onClick={() => setOpenBetSlip(!openBetSlip)}
              variant="outlined"
              sx={{
                p: 1,
                ml: 1,
                height: 34,
                display: 'flex',
                flexDirection: 'column',
                ...(!openBetSlip && {
                  borderColor: 'custom.content.3',
                  bgcolor: 'custom.bg.2',
                }),
                whiteSpace: 'nowrap',
                flexShrink: 0,
              }}>
              <Box component="span" sx={{ fontSize: 11, fontWeight: 700 }}>
                <Trans id="bets">Bets {betSlipLength}</Trans>
              </Box>
              <Box
                component="span"
                sx={{
                  fontSize: 10,
                  color: 'custom.content.2',
                  fontWeight: 400,
                }}>
                <Trans id="bets.active">Active {activeBetsLength}</Trans>
              </Box>
            </Button>
          ) : (
            <>
              <Button
                color="inherit"
                variant="outlined"
                sx={{ mr: 1.5, [QueryMapping.mobile]: { maxHeight: '30px' } }}
                onClick={() => {
                  trackRegisterInit('header_button');
                  setRegister(true);
                }}>
                <Typography component="span" noWrap fontWeight="700">
                  <Trans id="auth.register">Register</Trans>
                </Typography>
              </Button>
              <Button
                color="primary"
                variant="contained"
                sx={{ [QueryMapping.mobile]: { maxHeight: '30px' } }}
                onClick={() => setLogin(true)}>
                <Typography component="span" noWrap fontWeight="700">
                  <Trans id="auth.login">Login</Trans>
                </Typography>
              </Button>
            </>
          )}
        </Container>
      </Toolbar>
    </AppBar>
  );
};
