import { Trans } from '@lingui/macro';
import {
  Box,
  BoxProps,
  IconButton,
  Modal,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-use';
import { HotBets } from 'src/_pages/home/main/components/hot-bet';
import CloseIcon from 'src/assets/icons/close.svg';
import FireIcon from 'src/assets/icons/hot-event.svg?url';
import SearchIcon from 'src/assets/icons/search.svg';
import FeaturedLeagues from 'src/components/modules/featured-leagues';
import { Events } from 'src/components/modules/navbar/search-bar/events';
import TopCasinoGames from 'src/components/modules/top-casino-games';
import { QueryMapping } from 'src/hooks/use-breakpoint';
import { SearchInput } from './search-input';

const MobileSearchBar: React.FCC<BoxProps> = (props) => {
  const location = useLocation();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    handleClose();
  }, [location]);

  return (
    <Box {...props}>
      <IconButton sx={{ outline: 'none !important' }} onClick={handleOpen}>
        <SearchIcon width="16px" height="16px" />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{ backgroundColor: 'common.black' }}>
        <>
          <Stack direction="row" py="16px">
            <Box sx={{ flexGrow: 1 }}>
              <SearchInput onSearch={handleClose} />
            </Box>
            <IconButton onClick={handleClose} size="large">
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack paddingLeft="16px" gap="24px" height="100%">
            <Typography
              sx={{
                fontSize: 18,
                fontWeight: 700,
                display: 'flex',
                alignItems: 'center',
              }}>
              <Trans id="home.check_out_what_hot">
                or check out what’s hot!
              </Trans>
              <Box
                component="img"
                src={FireIcon.src}
                sx={{
                  width: 18,
                  height: 18,
                  marginLeft: '4px',
                }}
              />
            </Typography>
            <HotBets onClick={handleClose} />
            <TopCasinoGames
              title={<Trans id="home.top.casino_games">Casino games</Trans>}
            />
            <Events />
            <FeaturedLeagues
              title={<Trans id="left_nav.sports">Sports</Trans>}
            />
          </Stack>
        </>
      </Modal>
    </Box>
  );
};

export const SearchBar: React.FCC<{ sxMobile: SxProps }> = ({ sxMobile }) => {
  return (
    <>
      <SearchInput
        sx={{
          minWidth: 140,
          flex: 1,
          [QueryMapping.mobile]: { display: 'none' },
        }}
      />

      <MobileSearchBar
        sx={{
          flex: 1,
          mx: 1,
          textAlign: 'end',
          display: 'none',
          [QueryMapping.mobile]: {
            ...sxMobile,
            display: 'block',
          },
        }}
      />
    </>
  );
};
