import { Box, Drawer, typographyClasses } from '@mui/material';
import { atom, useAtom } from 'jotai';
import _ from 'lodash';
import { QueryMapping, useBreakpoint } from 'src/hooks/use-breakpoint';
import { useLocale } from 'src/hooks/use-locale';
import { useQuery } from 'src/hooks/use-query';
import {
  betSlipsAtom,
  hasCanceledAtom,
  hasCrossErrorAtom,
  hasSameEventErrorAtom,
  maxBetErrorAtom,
  modeBetSlipAtom,
  openBetSlipAtom,
  openMultiBetAtom,
} from 'src/store/bet-slip';
import { Paths } from 'src/utils/constants/path';
import { MyDevices } from 'src/utils/helpers/etc';
import { SportTypeEnum } from 'src/utils/types/sport';
import { BetSlipModeType } from 'src/utils/types/sportsbook';
import { BOTTOM_NAV_ZINDEX } from '../navbar/bottom-nav/styles';
import { Canceled } from './states/canceled';
import { HasError, ignoredAtom } from './states/has-error';
import { Normal } from './states/normal';

interface MultiBetDrawerProps {}

const myStateAtom = atom(
  (get) => {
    const ignored = get(ignoredAtom);
    const open = get(openMultiBetAtom);
    const items = get(betSlipsAtom);

    const canceled = get(hasCanceledAtom);
    const hasError = (():
      | { maxBet?: true; crossBet?: true; sameEvent?: true }
      | false => {
      if (items.length < 2) return false;

      if (get(maxBetErrorAtom)) return { maxBet: true };
      if (get(hasCrossErrorAtom)) return { crossBet: true };
      if (get(hasSameEventErrorAtom)) return { sameEvent: true };

      return false;
    })();

    return {
      ignored,
      open:
        open &&
        _.uniqWith(items, (a, b) => a.eventId === b.eventId).length >= 2,
      hasError,
      canceled,
    };
  },
  (get, set) => {
    set(openBetSlipAtom, true);
    set(modeBetSlipAtom, BetSlipModeType.Multi);
  },
);

const sportTypes: string[] = [SportTypeEnum.Sport, SportTypeEnum.ESport];

export const MultiBetDrawer: React.FC<MultiBetDrawerProps> = (props) => {
  const mobile = useBreakpoint(MyDevices.MOBILE);
  const { sportType, eventId } = useQuery(['sportType', 'eventId']);
  const { pathname } = useLocale();
  const [{ open, ignored, hasError, canceled }, openBetslip] =
    useAtom(myStateAtom);

  const isValidPage =
    pathname === Paths.Home ||
    sportTypes.includes(sportType) ||
    !_.isEmpty(eventId);

  return (
    <Drawer
      anchor="bottom"
      open={mobile && open && !ignored && isValidPage}
      variant="persistent"
      PaperProps={{
        sx: {
          display: 'none',
          [QueryMapping.mobile]: {
            display: 'flex',
          },
          zIndex: BOTTOM_NAV_ZINDEX - 1,
          mb: '75px',
          bgcolor: 'transparent',
          border: 0,
          pt: 1,
          mt: -1,
        },
      }}
      ModalProps={{ keepMounted: true }}>
      <Box
        onClick={openBetslip}
        sx={{
          mx: 1.5,
          px: 2,
          py: 1,
          bgcolor: '#EBECED',
          color: '#23232D',
          borderRadius: '14px 14px 0 0',
          boxShadow: '0px -2px 4px 0px rgba(0, 0, 0, 0.25)',
          display: 'flex',
          alignItems: 'center',
          minHeight: 45,
          [`& .${typographyClasses.root}`]: {
            lineHeight: 5 / 4,
          },
        }}>
        {canceled ? (
          <Canceled />
        ) : hasError ? (
          <HasError {...hasError} />
        ) : (
          <Normal />
        )}
      </Box>
    </Drawer>
  );
};
