import { Trans } from '@lingui/macro';
import {
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import React, { useState } from 'react';
import { useCopyToClipboard } from 'react-use';
import CopyIcon from 'src/assets/icons/copy.svg';
import { sleep } from 'src/utils/helpers/async-helpers';

interface CopyToClipBoardProps
  extends Omit<TooltipProps, 'title' | 'children'> {
  title?: React.ReactNode;
  getMsg?: string | (() => string) | (() => Promise<string>);
  renderItem?: (copied: boolean) => React.ReactNode;
  IconButtonProps?: IconButtonProps;
  BoxProps?: BoxProps;
  svgProps?: React.SVGProps<SVGSVGElement>;
}

export const CopyToClipBoard: React.FCC<CopyToClipBoardProps> = (props) => {
  const {
    BoxProps,
    getMsg,
    children,
    title = <Trans id="common.copied">Copied!</Trans>,
    svgProps,
    IconButtonProps,
    componentsProps,
    renderItem,
    onClick,
    ...rest
  } = props;

  const [open, setOpen] = useState(false);

  const [, doCopy] = useCopyToClipboard();

  const handleClick = async (e: any) => {
    onClick?.(e);
    setOpen(true);
    const _msg = typeof getMsg === 'string' ? getMsg : await getMsg?.();
    _msg && doCopy(_msg);
    await sleep(2000);
    setOpen(false);
  };

  return (
    <Tooltip
      placement="right"
      {...rest}
      componentsProps={{
        ...componentsProps,
        tooltip: {
          ...componentsProps?.tooltip,
          sx: { py: 0.25, px: 0.5, ...componentsProps?.tooltip?.sx },
        },
      }}
      title={title}
      open={open}
      onClose={() => setOpen(false)}
      disableFocusListener
      disableHoverListener
      disableTouchListener>
      <Box {...BoxProps} onClick={handleClick}>
        {children ?? renderItem?.(open) ?? (
          <IconButton size="small" edge="end" {...IconButtonProps}>
            <CopyIcon width={12} height={12} {...svgProps} />
          </IconButton>
        )}
      </Box>
    </Tooltip>
  );
};
