import { Trans } from '@lingui/macro';
import { Box, Typography } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { OddsArrow } from 'src/components/core/odds-arrow';
import { useRoom } from 'src/hooks/socket/use-room';
import { useLocale } from 'src/hooks/use-locale';
import { betSlipsAtom } from 'src/store/bet-slip';
import { SocketEvent } from 'src/utils/constants/socket-event';
import { mergeBetSlipWithOddsChange } from 'src/utils/helpers/async-helpers';
import { BN } from 'src/utils/helpers/big-number';
import { ignoredAtom } from './has-error';

interface NormalProps {}

const SIMULATED_STAKE_USD = 10;

export const Normal: React.FC<NormalProps> = (props) => {
  const betslips = useAtomValue(betSlipsAtom);
  const { FORMAT } = useLocale();
  const totalOdds = _.reduce(
    betslips,
    (prev, curr) => {
      return prev.times(curr.currentOdd);
    },
    new BN(1),
  );

  const setIgnored = useSetAtom(ignoredAtom);
  useEffect(() => {
    setIgnored(false);
  }, []);

  const [brOdds, setBrOdds] = useState(totalOdds);
  // only show arrow on odds_change
  useRoom(
    SocketEvent.ODD_CHANGE_V5,
    _.uniq(betslips.map((x) => x.eventId)),
    async ({ message }) => {
      const next = betslips.reduce((prev, curr) => {
        const changed = mergeBetSlipWithOddsChange(curr, message);
        return prev.times(changed.currentOdd);
      }, new BN(1));
      setBrOdds(next);
    },
  );

  return (
    <>
      <Box
        sx={{
          borderRadius: 4,
          minWidth: 24,
          height: 24,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'currentcolor',
          px: 0.5,
        }}>
        <Typography
          variant="caption"
          component="span"
          sx={{ color: 'custom.content.1', fontWeight: 700 }}>
          {betslips.length}
        </Typography>
      </Box>

      <Typography
        noWrap
        sx={{
          flex: 1,
          fontSize: 13,
          ml: 1,
          fontWeight: 700,
          minWidth: 'fit-content',
        }}>
        <Trans id="multi_bet.selections">Selections</Trans>
      </Typography>

      <Box sx={{ mx: 1, flexShrink: 0 }}>
        <Typography sx={{ fontSize: 13, fontWeight: 700 }}>
          <Trans id="multi_bet.est_payout">est payout</Trans>
        </Typography>

        <Typography sx={{ fontSize: 10, fontWeight: 400, color: '#5D5D5D' }}>
          <Trans id="multi_bet.simulated_stake">
            on a ${SIMULATED_STAKE_USD} stake
          </Trans>
        </Typography>
      </Box>

      <Typography
        noWrap
        sx={{
          fontSize: 18,
          fontWeight: 700,
        }}>
        {new BN(SIMULATED_STAKE_USD)
          .times(totalOdds.dp(2))
          .toFormat({ ...FORMAT, prefix: '$' })}
      </Typography>
      <Box sx={{ width: 12, height: 12, ml: 0.5, flexShrink: 0 }}>
        <OddsArrow odds={brOdds.toFixed(2)} />
      </Box>
    </>
  );
};
