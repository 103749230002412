import { Trans } from '@lingui/macro';
import { Box, Typography } from '@mui/material';
import { useHotEvents } from 'src/components/modules/hot-events';
import EventItem from 'src/components/modules/navbar/search-bar/event-item';
import { QueryMapping } from 'src/hooks/use-breakpoint';

interface HotBetsProps {}

export const Events: React.FC<HotBetsProps> = () => {
  const { data } = useHotEvents();

  if (data?.items.length === 0) return null;

  return (
    <Box>
      <Typography
        gutterBottom
        sx={{
          fontSize: 18,
          fontWeight: 700,
        }}>
        <Trans id="sportsbook.events">Events</Trans>
      </Typography>

      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          flexWrap: 'wrap',
          [QueryMapping.mobile]: {
            flexWrap: 'nowrap',
            overflowY: 'hidden',
            scrollSnapType: 'x mandatory',
            scrollbarWidth: 'none',
            overflowX: 'scroll',
          },
        }}>
        {data?.items.flatMap((item) => {
          const name = `${item.competitor1} vs ${item.competitor2}`;
          return <EventItem data={item} key={item._id} name={name} />;
        })}
      </Box>
    </Box>
  );
};
