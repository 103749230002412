import { Box } from '@mui/material';
import React from 'react';
import { ChangeUsername } from 'src/_pages/home/main/components/change-username';
import { Footer } from 'src/components/modules/footer';
import { DetailDialog } from 'src/components/modules/leaderboard/sport/detail-dialog';
import { MultiBetDrawer } from 'src/components/modules/multi-bet-drawer';
import { NavBar } from 'src/components/modules/navbar';
import { BottomNav } from 'src/components/modules/navbar/bottom-nav';
import { DrawersOrLogin } from 'src/components/templates/basic-template/drawer-or-login';
import { Main } from 'src/components/templates/basic-template/main';

export const BasicTemplate: React.FCC = ({ children }) => {
  return (
    <Main>
      <DetailDialog />
      <NavBar />
      <DrawersOrLogin />
      <Box component="main" sx={{ py: { xs: 2, md: 3 }, flexGrow: 1 }}>
        {children}
      </Box>
      <BottomNav />
      <Footer />

      <MultiBetDrawer />

      <ChangeUsername />
    </Main>
  );
};
